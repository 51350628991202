<template>
  <div class="page-perp flex-col">
    <div class="per-bd1 flex-col">
      <div class="per-mod1 flex-col">
        <div class="per-layer1 flex-col">
          <headInfo :type="type"></headInfo>
          <span class="per-txt3"
            >专业&nbsp;ERP&nbsp;&nbsp;&nbsp;满足您的需求</span
          >
          <span class="per-info3">为企业提供简洁高效的解决方案</span>
          <!-- <div class="per-box3 flex-col" @click="toNewPage(5)">
            <span class="per-info4">立即试用</span>
          </div> -->
        </div>
        <div class="per-layer3 flex-col">
          <div class="per-main1 flex-col"></div>
        </div>
      </div>
      <span class="per-word3">产品介绍</span>
      <span class="per-paragraph1"
        >充分调动企业内部人、财、物、信息、时间、空间等，并将这些资源根据需要划分到相应部门<br />实现资源整合、充分利用</span
      >
      <div class="per-mod2 flex-row">
        <div class="per-wrap6 flex-col"></div>
        <div class="per-wrap7 flex-col"></div>
        <div class="per-wrap8 flex-col"></div>
        <div class="per-wrap9 flex-col"></div>
      </div>
      <div class="per-mod3 flex-row">
        <span class="per-word4">强大业务流</span>
        <span class="per-word5">标准化管理</span>
        <span class="per-word6">云端部署</span>
        <span class="per-word7">信息系统整合</span>
      </div>
      <div class="per-mod4 flex-row justify-between">
        <span class="per-txt4"
          >自定义业务审批流程，灵活部署，各种模板随意选择</span
        >
        <span class="per-word8"
          >帮助企业进行标准化管理，提升各部门之间业务对接效率</span
        >
        <span class="per-info5"
          >云端部署、无需搭建、省时省力、轻松高效，兼顾易用性和个性能力</span
        >
        <span class="per-info6"
          >从采购、生产到销售、回款，全流程信息整合</span
        >
      </div>
      <div class="per-mod5 flex-col">
        <div class="per-layer4 flex-col">
          <span class="per-word9">产品亮点</span>
          <span class="per-info7"
            >为企业各部门打通信息沟通壁垒，为管理者运筹帷幄提供实时、生动、精准的智能大数据分析</span
          >
          <div class="per-layer5 flex-col"></div>
          <div class="per-layer6 flex-row justify-between">
            <div class="per-group1 flex-col">
              <div class="per-box4 flex-col">
                <div class="per-bd2 flex-col">
                  <span class="per-info8">强大流程引擎</span>
                  <span class="per-paragraph2"
                    >自主知识产权<br />快速建立内部管理机制<br />帮助企业快速畅通运转</span
                  >
                  <img
                    class="per-pic2"
                    referrerpolicy="no-referrer"
                    src="@/assets/main/productright.png"
                  />
                </div>
              </div>
              <img
                class="per-pic3"
                referrerpolicy="no-referrer"
                src="@/assets/images/procedure/erphighone.png"
              />
            </div>
            <div class="per-group2 flex-row">
              <div
                class="per-group3 flex-col"
                :style="{ background: item.lanhuBg0 }"
                v-for="(item, index) in loopData0"
                :key="index"
              >
                <div
                  class="per-layer7 flex-col"
                  :style="{ background: item.lanhuBg1 }"
                >
                  <div class="per-bd3 flex-col">
                    <span class="per-txt5" v-html="item.lanhutext0"></span>
                    <span class="per-info9" v-html="item.lanhutext1"></span>
                    <img
                      class="per-img1"
                      referrerpolicy="no-referrer"
                      :src="item.lanhuimage0"
                    />
                  </div>
                </div>
                <img
                  class="per-img2"
                  referrerpolicy="no-referrer"
                  :src="item.lanhuimage1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <span class="per-info10">增值服务</span>
      <span class="per-txt6">多种增值服务任选，全程追踪配置</span>
      <div class="per-mod6 flex-row justify-between">
        <div class="per-outer1 flex-col">
          <div class="per-section1 flex-row">
            <span class="per-info11">PDA仓储助手</span>
          </div>
          <div class="per-section2 flex-row justify-between">
            <div class="per-group11 flex-col"></div>
            <span class="per-info12">移动仓内作业支持</span>
          </div>
          <div class="per-section3 flex-row justify-between">
            <div class="per-main24 flex-col"></div>
            <span class="per-word10">扫描出入库提升效率</span>
          </div>
          <div class="per-bd18 flex-col"></div>
        </div>
        <div class="per-outer2 flex-col">
          <div class="per-bd4 flex-row">
            <span class="per-txt7">全网分销</span>
          </div>
          <div class="per-bd5 flex-row justify-between">
            <div class="per-main25 flex-col"></div>
            <span class="per-word11">线上代发+线下代理</span>
          </div>
          <div class="per-bd6 flex-row justify-between">
            <div class="per-wrap10 flex-col"></div>
            <span class="per-txt8">精细管控，库存同步</span>
          </div>
          <div class="per-bd18 flex-col"></div>
        </div>
        <div class="per-outer3 flex-col">
          <div class="per-layer8 flex-row">
            <span class="per-txt9">电子发票</span>
          </div>
          <div class="per-layer9 flex-row justify-between">
            <div class="per-group12 flex-col"></div>
            <span class="per-word12">支持全平台订单开票</span>
          </div>
          <div class="per-layer10 flex-row justify-between">
            <div class="per-mod41 flex-col"></div>
            <span class="per-word13">获取便捷，方便查询</span>
          </div>
          <div class="per-bd18 flex-col"></div>
        </div>
        <div class="per-outer4 flex-col">
          <div class="per-mod7 flex-row">
            <span class="per-word14">金税对接</span>
          </div>
          <div class="per-mod8 flex-row justify-between">
            <div class="per-bd19 flex-col"></div>
            <span class="per-word15">开票信息自动推送</span>
          </div>
          <div class="per-mod9 flex-row justify-between">
            <div class="per-section5 flex-col"></div>
            <span class="per-word16">金税开票，货票同行</span>
          </div>
          <div class="per-bd18 flex-col"></div>
        </div>
      </div>
      <div class="per-mod10 flex-col">
        <div class="per-mod11 flex-row">
          <span class="per-word17">核心功能</span>
        </div>
        <div class="per-mod12 flex-row">
          <div class="per-section4 flex-col"></div>
        </div>
        <div class="per-mod13 flex-row justify-between">
          <img
            class="per-pic11"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/erpcoreone.png"
          />
          <img
            class="per-pic12"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/erpcoretwo.png"
          />
          <img
            class="per-img20"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/erpcorethird.png"
          />
          <img
            class="per-pic13"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/erpcorefour.png"
          />
          <img
            class="per-img21"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/erpcorefive.png"
          />
        </div>
        <div class="per-mod14 flex-row">
          <span class="per-word18">订单实时同步</span>
          <span class="per-word19">库存同步</span>
          <span class="per-word20">智能仓库匹配</span>
          <span class="per-word21">智能快递匹配</span>
          <span class="per-word22">智能订单配货</span>
        </div>
        <div class="per-mod15 flex-row">
          <span class="per-paragraph3">全渠道订单实时同步<br />集中审核</span>
          <span class="per-infoBox1">多种组合<br />配合多种公式算法</span>
          <span class="per-infoBox2">异地多仓<br />几十种仓储匹配策略</span>
          <span class="per-paragraph4"
            >结合成本、时效、范围等<br />智能匹配</span
          >
          <span class="per-paragraph5">高效、智能<br />订单波次分组</span>
        </div>
        <div class="per-mod16 flex-row justify-between">
          <img
            class="per-img22"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/erpcoresix.png"
          />
          <img
            class="per-pic14"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/erpcoreseven.png"
          />
          <img
            class="per-img23"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/erpcoreeight.png"
          />
          <img
            class="per-img24"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/erpcorenine.png"
          />
          <img
            class="per-img25"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/erpcoreten.png"
          />
        </div>
        <div class="per-mod17 flex-row">
          <span class="per-info13">精细化售后</span>
          <span class="per-info14">全方位会员管理</span>
          <span class="per-word23">财务总账</span>
          <span class="per-word24">分销数据</span>
          <span class="per-word25">人事管理</span>
        </div>
        <div class="per-mod18 flex-row justify-between">
          <span class="per-paragraph6"
            >售后状态分类分角色处理<br />淘宝售后单支持跨店</span
          >
          <span class="per-infoBox3"
            >全渠道会员识别，精准营销<br />指定发送、个性化包裹</span
          >
          <span class="per-word26"
            >多平台、多渠道，全方位对账，精细每一笔财流</span
          >
          <span class="per-paragraph7">分销商订单管理<br />数据追溯</span>
          <span class="per-paragraph8"
            >协助人事部门管理企业员工<br />办公协同</span
          >
        </div>
        <div class="per-mod19 flex-row justify-between">
          <img
            class="per-img26"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/erpcore11.png"
          />
          <img
            class="per-pic15"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/erpcore12.png"
          />
          <img
            class="per-img27"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/erpcore13.png"
          />
          <img
            class="per-img28"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/erpcore14.png"
          />
          <img
            class="per-pic16"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/erpcore15.png"
          />
        </div>
        <div class="per-mod20 flex-row justify-between">
          <span class="per-txt10">预售规则</span>
          <span class="per-word27">异常拦截</span>
          <span class="per-word28">生产控制</span>
          <span class="per-info15">采购管理</span>
          <span class="per-word29">自动审单</span>
        </div>
        <div class="per-mod21 flex-row justify-between">
          <span class="per-paragraph9"
            >支持多种、多平台预售规则<br />和营销玩法</span
          >
          <span class="per-infoBox4"
            >未付款/退款/黑名单订单<br />自动拦截提交异常</span
          >
          <span class="per-txt11"
            >把控原材料生产，从生产、加工、存储、售卖全流程</span
          >
          <span class="per-paragraph10">采购订单管理<br />供应商招投标</span>
          <span class="per-infoBox5">近20种自动审单纬度<br />覆盖主流场景</span>
        </div>
      </div>
      <span class="per-txt12">产品架构</span>
      <span class="per-info16"
        >订单管理、库存管理、分销管理、财务管理、物流管理等全方位构建</span
      >
      <div class="per-mod22 flex-col">
        <div class="per-box7 flex-col justify-between">
          <!-- <div class="per-group7 flex-col">
            <div class="per-wrap1 flex-row justify-between">
              <div class="per-main9 flex-col"></div>
              <div class="per-main10 flex-col">
                <span class="per-word30">帐号管理</span>
              </div>
              <div class="per-main11 flex-col">
                <span class="per-word31">权限管理</span>
              </div>
              <div class="per-main12 flex-col">
                <span class="per-word32">基础资料</span>
              </div>
              <div class="per-main13 flex-col">
                <span class="per-info17">编码原则</span>
              </div>
              <div class="per-main14 flex-col">
                <span class="per-txt13">自定义报表</span>
              </div>
            </div>
            <div class="per-wrap2 flex-row justify-between">
              <div class="per-main15 flex-col"></div>
              <div class="per-main16 flex-col">
                <span class="per-txt14">容器管理</span>
              </div>
              <div class="per-main17 flex-col">
                <span class="per-word33">采购进货</span>
              </div>
              <div class="per-main18 flex-col">
                <span class="per-txt15">质检验收</span>
              </div>
              <div class="per-main19 flex-col">
                <span class="per-word34">库存异动</span>
              </div>
              <div class="per-main20 flex-col">
                <span class="per-word35">批号管理</span>
              </div>
            </div>
            <div class="per-wrap3 flex-row justify-between">
              <div class="per-bd7 flex-col">
                <span class="per-word36">库存盘点</span>
              </div>
              <div class="per-bd8 flex-col">
                <span class="per-info18">供应商管理</span>
              </div>
              <div class="per-bd9 flex-col">
                <span class="per-word37">补货预警</span>
              </div>
              <div class="per-bd10 flex-col">
                <span class="per-txt16">报价销货</span>
              </div>
              <div class="per-bd11 flex-col">
                <span class="per-word38">……</span>
              </div>
            </div>
            <div class="per-wrap4 flex-row justify-between">
              <div class="per-block1 flex-col"></div>
              <div class="per-block2 flex-col">
                <span class="per-info19">产品结构</span>
              </div>
              <div class="per-block3 flex-col">
                <span class="per-txt17">主生产排期</span>
              </div>
              <div class="per-block4 flex-col">
                <span class="per-txt18">物料需求计划</span>
              </div>
              <div class="per-block5 flex-col">
                <span class="per-info20">批次需求计划</span>
              </div>
              <div class="per-block6 flex-col">
                <span class="per-info21">工单工艺</span>
              </div>
            </div>
            <div class="per-wrap5 flex-row justify-between">
              <div class="per-bd12 flex-col"></div>
              <div class="per-bd13 flex-col">
                <span class="per-txt19">应收应付</span>
              </div>
              <div class="per-bd14 flex-col">
                <span class="per-txt20">会计总账</span>
              </div>
              <div class="per-bd15 flex-col">
                <span class="per-txt21">自动分录</span>
              </div>
              <div class="per-bd16 flex-col">
                <span class="per-word39">成本计算</span>
              </div>
              <div class="per-bd17 flex-col">
                <span class="per-word40">固定资产</span>
              </div>
            </div>
            <span class="per-word41">系统设置</span>
            <span class="per-word42">进销存管理</span>
            <span class="per-word43">生产管理</span>
            <span class="per-word44">财务管理</span>
          </div>
          <div class="per-group8 flex-col">
            <div class="per-main21 flex-row justify-between">
              <div class="per-box8 flex-col"></div>
              <div class="per-box9 flex-col">
                <span class="per-word45">OA</span>
              </div>
              <div class="per-box10 flex-row justify-between">
                <div class="per-block7 flex-col">
                  <span class="per-info22">客户关系</span>
                </div>
                <div class="per-block8 flex-col">
                  <span class="per-info23">人力资源</span>
                </div>
                <div class="per-block9 flex-col">
                  <span class="per-txt22">MES导航</span>
                </div>
              </div>
              <div class="per-box11 flex-col">
                <span class="per-txt23">……</span>
              </div>
            </div>
            <span class="per-info24">异构系统</span>
          </div> -->
        </div>
        <!-- <span class="per-txt24">整合</span> <span class="per-info25">集成</span>
        <img
          class="per-img13"
          referrerpolicy="no-referrer"
          src="@/assets/images/procedure/upper.png"
        />
        <img
          class="per-img14"
          referrerpolicy="no-referrer"
          src="@/assets/images/procedure/down.png"
        /> -->
      </div>
      <span class="per-txt25">典型案例</span>
      <div class="per-mod23 flex-row justify-between">
        <div class="per-main22 flex-col">
          <img
            class="per-img15"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/erpinsone.png"
          />
        </div>
        <div class="per-main23 flex-col">
          <span class="per-word46">道闸产业链</span>
          <span class="per-word47">生产制造类别</span>
          <div class="per-mod24 flex-col"></div>
          <span class="per-word48">痛点：</span>
          <span class="per-paragraph11"
            >1.代理商、工程商以及其他渠道客户难统一管理<br />2.成产销售流程不完整、不规范<br />3.库存管理混乱</span
          >
          <span class="per-info26">解决方案：</span>
          <span class="per-paragraph12"
            >1.松云ERP给与完整的客户管理方案，管理细节适配各类客户<br />2.销售和生产之间的订单流和客户服务信息集成<br />3.成产原料，成品库存精细化管理，各类报表覆盖各类仓储数据</span
          >
          <div class="per-mod25 flex-row">
            <span class="per-word49">订单处理</span>
            <span class="per-word50">80%</span>
            <div class="per-block10 flex-col"></div>
            <span class="per-word51">40%</span>
            <span class="per-info27">提高获客效率</span>
            <div class="per-block11 flex-col"></div>
          </div>
          <!-- <router-link :to="{path:'../instance?#header-ident'}" style="text-decoration: none"> -->
          <button class="per-mod26 flex-col" @click="jumpPage('instance','header-ident')">
            <span class="per-txt26">查看详情</span>
          </button>
          <!-- </router-link> -->
        </div>
      </div>
      <secFoot />
      <footInfo />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      type: 1,
      loopData0: [
        {
          lanhuBg0:
            "url("+require('../../assets/images/back/erpgreyone.png')+") 100% no-repeat",
          lanhuBg1:
            "url("+require('../../assets/images/back/erpgreytwo.png')+") 100% no-repeat",
          lanhutext0: "全报表覆盖",
          lanhutext1: "多种报表明细<br/>减轻工作负担<br/>数据一目了然",
          lanhuimage0:
            require("../../assets/main/productright.png"),
          lanhuimage1:
            require("../../assets/images/procedure/erphightwo.png"),
        },
        {
          lanhuBg0:
            "url("+require('../../assets/images/back/erpgreyone.png')+") 100% no-repeat",
          lanhuBg1:
            "url("+require('../../assets/images/back/erpgreytwo.png')+") 100% no-repeat",
          lanhutext0: "高灵活性",
          lanhutext1: "开源代码平台<br/>迅速配置<br/>灵活性高",
          lanhuimage0:
            require("../../assets/main/productright.png"),
          lanhuimage1:
             require("../../assets/images/procedure/erphighthird.png"),
        },
        {
          lanhuBg0:
            "url("+require('../../assets/images/back/erpgreythird.png')+") 100% no-repeat",
          lanhuBg1:
            "url("+require('../../assets/images/back/erpgreyfour.png')+") 100% no-repeat",
          lanhutext0: "分析预测",
          lanhutext1: "提高企业成本管理<br/>损益分析效率<br/>企业利益最大化",
          lanhuimage0:
            require("../../assets/main/productright.png"),
          lanhuimage1:
            require("../../assets/images/procedure/erphighfour.png"),
        },
      ],
      constants: {},
    };
  },
  methods: {
    // onClick_1() {
    //   alert(1);
    // },
    jumpPage(path,id) {
      var path=path
      var Id=id;
      localStorage.setItem('toId',Id);
      this.$router.push({
        name: path,
        params: {
          sel: 'five'
        }
      });
    },
    // 跳转页面
    toNewPage(index) {
      let url = "";
      // index的类型 0-主页 1-产品介绍 2-服务体系 3-典型案例 4-关于松云 5-预约注册
      switch (index) {
        case 0: //主页
          url = "/";
          break;
        case 1: // 产品介绍
          url = "/product/erp";
          break;
        case 2: // 服务体系
          url = "/system";
          break;
        case 3: //  典型案例
          url = "/instance/erp";
          break;
        case 4: //关于松云
          url = "/about";
          break;
        case 5: //预约注册
          url = "/register";
          break;
      }
      if (url == "") {
        return;
      }
      this.$router.push({ path: url });
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss">
.page-perp {
  position: relative;
  width: 1920px;
  height: 7882px;
  text-align: center;
  margin: 0 auto;
  background-color: rgba(250, 250, 250, 1);
  overflow: hidden;
  .per-bd1 {
    width: 1920px;
    height: 7882px;
    .per-mod1 {
      height: 920px;
      overflow: hidden;
      background: url(../../assets/images/back/graythird.png)
        100% no-repeat;
      width: 1920px;
      position: relative;
      .per-layer1 {
        z-index: 80;
        width: 1920px;
        height: 610px;
        .per-box1 {
          height: 100px;
          width: 1920px;
          .per-layer2 {
            width: 1775px;
            height: 42px;
            margin: 29px 0 0 65px;
            opacity: 1;
            .per-pic1 {
              width: 180px;
              height: 38px;
              margin-top: 2px;
            }
            .per-txt1 {
              width: 43px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 301px;
            }
            .per-info1 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiB;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .per-info2 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .per-word1 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .per-txt2 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .per-box2 {
              height: 42px;
              border-radius: 21px;
              border: 2px solid rgba(37, 184, 47, 1);
              margin-left: 346px;
              width: 149px;
              .per-word2 {
                width: 85px;
                height: 29px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(255, 255, 255, 0.94);
                font-size: 21px;
                letter-spacing: 0.41999998688697815px;
                font-family: AlibabaPuHuiTiR;
                white-space: nowrap;
                line-height: 29px;
                text-align: left;
                margin: 7px 0 0 32px;
              }
            }
          }
        }
        .per-txt3 {
          width: 573px;
          height: 65px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 52px;
          letter-spacing: 1.0399999618530273px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 65px;
          text-align: center;
          margin: 221px 0 0 674px;
        }
        .per-info3 {
          width: 411px;
          height: 52px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 28px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 52px;
          text-align: right;
          margin: 36px 0 0 755px;
        }
        .per-box3 {
          height: 68px;
          border-radius: 34px;
          background-color: rgba(37, 184, 47, 1);
          box-shadow: 0px 20px 40px 0px rgba(37, 184, 47, 0.32);
          width: 220px;
          margin: 68px 0 0 850px;
          .per-info4 {
            width: 113px;
            height: 38px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 28px;
            letter-spacing: 0.5600000023841858px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 38px;
            text-align: left;
            margin: 15px 0 0 54px;
          }
        }
      }
      .per-layer3 {
        z-index: 65;
        height: 920px;
        background: url(../../assets/main/erp.png)
          100% no-repeat;
        width: 1920px;
        position: absolute;
        left: 0;
        top: 0;
        .per-main1 {
          width: 1920px;
          height: 920px;
          // background-color: rgba(0, 0, 0, 0.5);
        }
      }
    }
    .per-word3 {
      width: 129px;
      height: 44px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(39, 39, 39, 1);
      font-size: 32px;
      letter-spacing: 0.6399999856948853px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 44px;
      text-align: left;
      margin: 120px 0 0 896px;
    }
    .per-paragraph1 {
      width: 898px;
      height: 64px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(102, 102, 102, 1);
      font-size: 18px;
      letter-spacing: 1.5px;
      font-family: AlibabaPuHuiTiR;
      line-height: 32px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 24px 0 0 511px;
    }
    .per-mod2 {
      width: 1187px;
      height: 117px;
      margin: 89px 0 0 357px;
      .per-wrap6 {
        width: 114px;
        height: 114px;
        background: url(../../assets/images/procedure/erpone.png)
          0px -1px no-repeat;
        margin-top: 3px;
      }
      .per-wrap7 {
        width: 100px;
        height: 110px;
        background: url(../../assets/images/procedure/erptwo.png) -1px -2px
          no-repeat;
        margin: 5px 0 0 253px;
      }
      .per-wrap8 {
        width: 132px;
        height: 100px;
        background: url(../../assets/images/procedure/erpthird.png) -1px
          0px no-repeat;
        margin: 6px 0 0 241px;
      }
      .per-wrap9 {
        width: 104px;
        height: 114px;
        background: url(../../assets/images/procedure/erpfour.png) -2px -1px
          no-repeat;
        margin-left: 243px;
      }
    }
    .per-mod3 {
      width: 1197px;
      height: 29px;
      margin: 54px 0 0 360px;
      .per-word4 {
        width: 106px;
        height: 29px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 21px;
        letter-spacing: 0.41999998688697815px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 29px;
        text-align: left;
      }
      .per-word5 {
        width: 106px;
        height: 29px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 21px;
        letter-spacing: 0.41999998688697815px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 29px;
        text-align: left;
        margin-left: 255px;
      }
      .per-word6 {
        width: 85px;
        height: 29px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 21px;
        letter-spacing: 0.41999998688697815px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 29px;
        text-align: center;
        margin-left: 265px;
      }
      .per-word7 {
        width: 127px;
        height: 29px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 21px;
        letter-spacing: 0.41999998688697815px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 29px;
        text-align: left;
        margin-left: 253px;
      }
    }
    .per-mod4 {
      width: 1325px;
      height: 56px;
      margin: 16px 0 0 291px;
      .per-txt4 {
        width: 245px;
        height: 56px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 28px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .per-word8 {
        width: 245px;
        height: 56px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 28px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .per-info5 {
        width: 245px;
        height: 56px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 28px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .per-info6 {
        width: 245px;
        height: 56px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 28px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .per-mod5 {
      height: 946px;
      background-color: rgba(37, 38, 37, 1);
      margin-top: 120px;
      width: 1920px;
      .per-layer4 {
        width: 1920px;
        height: 810px;
        margin-top: 68px;
        .per-word9 {
          width: 129px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 32px;
          letter-spacing: 0.6399999856948853px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 44px;
          text-align: left;
          margin-left: 896px;
        }
        .per-info7 {
          width: 788px;
          height: 32px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 18px;
          letter-spacing: 1.5px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 32px;
          text-align: center;
          margin: 24px 0 0 567px;
        }
        .per-layer5 {
          width: 84px;
          height: 6px;
          background-color: rgba(37, 184, 47, 1);
          margin: 12px 0 0 919px;
        }
        .per-layer6 {
          width: 1920px;
          height: 644px;
          margin-top: 48px;
          .per-group1 {
            z-index: 4;
            height: 644px;
            overflow: hidden;
            background: url(../../assets/images/back/erpgreyfive.png)
              100% no-repeat;
            width: 688px;
            position: relative;
            .per-box4 {
              z-index: 6;
              height: 644px;
              overflow: hidden;
              background: url(../../assets/images/back/erpgreysix.png)
                100% no-repeat;
              width: 688px;
              position: relative;
              .per-bd2 {
                width: 245px;
                height: 257px;
                margin: 248px 0 0 128px;
                .per-info8 {
                  width: 217px;
                  height: 50px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(255, 255, 255, 1);
                  font-size: 36px;
                  letter-spacing: 0.7200000286102295px;
                  font-family: AlibabaPuHuiTiM;
                  white-space: nowrap;
                  line-height: 50px;
                  text-align: left;
                }
                .per-paragraph2 {
                  width: 245px;
                  height: 84px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(255, 255, 255, 1);
                  font-size: 16px;
                  letter-spacing: 1.5px;
                  font-family: AlibabaPuHuiTiM;
                  line-height: 28px;
                  text-align: left;
                  margin-top: 16px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                .per-pic2 {
                  width: 55px;
                  height: 55px;
                  margin-top: 52px;
                }
              }
            }
            .per-pic3 {
              z-index: 5;
              position: absolute;
              left: 0;
              top: 0;
              width: 688px;
              height: 644px;
            }
          }
          .per-group2 {
            width: 1232px;
            height: 644px;
            justify-content: space-between;
            .per-group3 {
              z-index: 8;
              height: 644px;
              overflow: hidden;
              width: 411px;
              position: relative;
              .per-layer7 {
                z-index: 10;
                height: 644px;
                overflow: hidden;
                width: 411px;
                position: relative;
                .per-bd3 {
                  width: 245px;
                  height: 257px;
                  margin: 248px 0 0 80px;
                  .per-txt5 {
                    width: 181px;
                    height: 50px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(255, 255, 255, 1);
                    font-size: 36px;
                    letter-spacing: 0.7200000286102295px;
                    font-family: AlibabaPuHuiTiM;
                    white-space: nowrap;
                    line-height: 50px;
                    text-align: left;
                  }
                  .per-info9 {
                    width: 245px;
                    height: 84px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(255, 255, 255, 1);
                    font-size: 16px;
                    letter-spacing: 1.5px;
                    font-family: AlibabaPuHuiTiM;
                    line-height: 28px;
                    text-align: left;
                    margin-top: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                  .per-img1 {
                    width: 55px;
                    height: 55px;
                    margin-top: 52px;
                  }
                }
              }
              .per-img2 {
                z-index: 9;
                position: absolute;
                left: 0;
                top: 0;
                width: 411px;
                height: 644px;
              }
            }
          }
        }
      }
    }
    .per-info10 {
      width: 129px;
      height: 44px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(40, 40, 40, 1);
      font-size: 32px;
      letter-spacing: 0.6399999856948853px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 44px;
      text-align: left;
      margin: 120px 0 0 887px;
    }
    .per-txt6 {
      width: 898px;
      height: 32px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(102, 102, 102, 1);
      font-size: 18px;
      letter-spacing: 1.5px;
      font-family: AlibabaPuHuiTiR;
      white-space: nowrap;
      line-height: 32px;
      text-align: center;
      margin: 24px 0 0 511px;
    }
    .per-mod6 {
      width: 1520px;
      height: 396px;
      margin: 52px 0 0 201px;
      .per-outer1 {
        height: 396px;
        border: 1px solid rgba(245, 245, 245, 1);
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
        width: 359px;
        .per-section1 {
          width: 147px;
          height: 33px;
          margin: 92px 0 0 106px;
          .per-info11 {
            width: 147px;
            height: 33px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 24px;
            letter-spacing: 0.47999998927116394px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 33px;
            text-align: left;
          }
        }
        .per-section2 {
          width: 167px;
          height: 28px;
          margin: 36px 0 0 82px;
          .per-group11 {
            width: 21px;
            height: 21px;
            background: url(../../assets/images/procedure/tick.png)
              0px 0px no-repeat;
            margin-top: 4px;
          }
          .per-info12 {
            width: 137px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: left;
          }
        }
        .per-section3 {
          width: 184px;
          height: 28px;
          margin: 24px 0 155px 82px;
          .per-main24 {
            width: 21px;
            height: 22px;
            background: url(../../assets/images/procedure/tick.png)
              0px 0px no-repeat;
            margin-top: 4px;
          }
          .per-word10 {
            width: 154px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: left;
          }
        }
      }
      .per-outer1:hover {
        background-color: rgba(37, 184, 47, 1);
        box-shadow: 0px 20px 40px 0px rgba(37, 184, 47, 0.24);
        .per-info11 {
          color: rgba(255, 255, 255, 1);
        }
        .per-info12 {
          color: rgba(255, 255, 255, 1);
        }
        .per-word10 {
          color: rgba(255, 255, 255, 1);
        }
        .per-group11 {
          background: url(../../assets/images/procedure/whitetick.png);
        }
        .per-main24 {
          background: url(../../assets/images/procedure/whitetick.png)
            0px 0px no-repeat;
        }
        .per-bd18 {
          z-index: 252;
          position: absolute;
          left: 313px;
          top: 3254px;
          width: 145px;
          height: 25px;
          // background: url(../../assets/images/back/greencloth.png) -57px -70px
          //   no-repeat;
          background: #00FF11;
          filter: blur(28px);
        }
      }
      .per-outer2 {
        height: 396px;
        border: 1px solid rgba(245, 245, 245, 1);
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
        width: 360px;
        .per-bd4 {
          width: 97px;
          height: 33px;
          margin: 92px 0 0 132px;
          .per-txt7 {
            width: 97px;
            height: 33px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 24px;
            letter-spacing: 0.47999998927116394px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 33px;
            text-align: left;
          }
        }
        .per-bd5 {
          width: 177px;
          height: 28px;
          margin: 36px 0 0 82px;
          .per-main25 {
            width: 22px;
            height: 21px;
            background: url(../../assets/images/procedure/tick.png)
              0px 0px no-repeat;
            margin-top: 4px;
          }
          .per-word11 {
            width: 147px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: left;
          }
        }
        .per-bd6 {
          width: 184px;
          height: 28px;
          margin: 24px 0 155px 82px;
          .per-wrap10 {
            width: 22px;
            height: 22px;
            background: url(../../assets/images/procedure/tick.png)
              0px 0px no-repeat;
            margin-top: 4px;
          }
          .per-txt8 {
            width: 154px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: left;
          }
        }
      }
      .per-outer2:hover {
        background-color: rgba(37, 184, 47, 1);
        box-shadow: 0px 20px 40px 0px rgba(37, 184, 47, 0.24);
        .per-txt7 {
          color: rgba(255, 255, 255, 1);
        }
        .per-word11 {
          color: rgba(255, 255, 255, 1);
        }
        .per-txt8 {
          color: rgba(255, 255, 255, 1);
        }
        .per-main25 {
          background: url(../../assets/images/procedure/whitetick.png);
        }
        .per-wrap10 {
          background: url(../../assets/images/procedure/whitetick.png)
            0px 0px no-repeat;
        }
        .per-bd18 {
          z-index: 252;
          position: absolute;
          left: 701px;
          top: 3254px;
          width: 145px;
          height: 25px;
          background: #00FF11;
          filter: blur(28px);
        }
      }
      .per-outer3 {
        height: 396px;
        border: 1px solid rgba(245, 245, 245, 1);
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
        width: 358px;
        .per-layer8 {
          width: 97px;
          height: 33px;
          margin: 92px 0 0 131px;
          .per-txt9 {
            width: 97px;
            height: 33px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 24px;
            letter-spacing: 0.47999998927116394px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 33px;
            text-align: left;
          }
        }
        .per-layer9 {
          width: 184px;
          height: 28px;
          margin: 36px 0 0 81px;
          .per-group12 {
            width: 21px;
            height: 21px;
            background: url(../../assets/images/procedure/tick.png)
              0px 0px no-repeat;
            margin-top: 4px;
          }
          .per-word12 {
            width: 154px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: left;
          }
        }
        .per-layer10 {
          width: 184px;
          height: 28px;
          margin: 24px 0 155px 81px;
          .per-mod41 {
            width: 21px;
            height: 22px;
            background: url(../../assets/images/procedure/tick.png)
              0px 0px no-repeat;
            margin-top: 4px;
          }
          .per-word13 {
            width: 154px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: left;
          }
        }
      }
      .per-outer3:hover {
        background-color: rgba(37, 184, 47, 1);
        box-shadow: 0px 20px 40px 0px rgba(37, 184, 47, 0.24);
        .per-txt9 {
          color: rgba(255, 255, 255, 1);
        }
        .per-word12 {
          color: rgba(255, 255, 255, 1);
        }
        .per-word13 {
          color: rgba(255, 255, 255, 1);
        }
        .per-group12 {
          background: url(../../assets/images/procedure/whitetick.png);
        }
        .per-mod41 {
          background: url(../../assets/images/procedure/whitetick.png)
            0px 0px no-repeat;
        }
        .per-bd18 {
          z-index: 252;
          position: absolute;
          left: 1089px;
          top: 3254px;
          width: 145px;
          height: 25px;
          background: #00FF11;
          filter: blur(28px);
        }
      }
      .per-outer4 {
        height: 396px;
        border: 1px solid rgba(245, 245, 245, 1);
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
        width: 358px;
        .per-mod7 {
          width: 97px;
          height: 33px;
          margin: 92px 0 0 131px;
          .per-word14 {
            width: 97px;
            height: 33px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 24px;
            letter-spacing: 0.47999998927116394px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 33px;
            text-align: left;
          }
        }
        .per-mod8 {
          width: 167px;
          height: 28px;
          margin: 36px 0 0 81px;
          .per-bd19 {
            width: 21px;
            height: 21px;
            background: url(../../assets/images/procedure/tick.png)
              0px 0px no-repeat;
            margin-top: 4px;
          }
          .per-word15 {
            width: 137px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: left;
          }
        }
        .per-mod9 {
          width: 184px;
          height: 28px;
          margin: 24px 0 155px 81px;
          .per-section5 {
            width: 21px;
            height: 22px;
            background: url(../../assets/images/procedure/tick.png)
              0px 0px no-repeat;
            margin-top: 4px;
          }
          .per-word16 {
            width: 154px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: left;
          }
        }
      }
      .per-outer4:hover {
        background-color: rgba(37, 184, 47, 1);
        box-shadow: 0px 20px 40px 0px rgba(37, 184, 47, 0.24);
        .per-word14 {
          color: rgba(255, 255, 255, 1);
        }
        .per-word15 {
          color: rgba(255, 255, 255, 1);
        }
        .per-word16 {
          color: rgba(255, 255, 255, 1);
        }
        .per-bd19 {
          background: url(../../assets/images/procedure/whitetick.png);
        }
        .per-section5 {
          background: url(../../assets/images/procedure/whitetick.png)
            0px 0px no-repeat;
        }
        .per-bd18 {
          z-index: 252;
          position: absolute;
          left: 1477px;
          top: 3254px;
          width: 145px;
          height: 25px;
          background: #00FF11;
          filter: blur(28px);
        }
      }
    }
    .per-mod10 {
      height: 1073px;
      background-color: rgba(37, 184, 47, 1);
      margin-top: 120px;
      width: 1920px;
      .per-mod11 {
        width: 129px;
        height: 44px;
        margin: 80px 0 0 887px;
        .per-word17 {
          width: 129px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 32px;
          letter-spacing: 0.6399999856948853px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 44px;
          text-align: left;
        }
      }
      .per-mod12 {
        width: 84px;
        height: 6px;
        margin: 24px 0 0 918px;
        .per-section4 {
          width: 84px;
          height: 6px;
          background-color: rgba(255, 255, 255, 1);
        }
      }
      .per-mod13 {
        width: 1111px;
        height: 120px;
        margin: 76px 0 0 404px;
        .per-pic11 {
          width: 120px;
          height: 120px;
        }
        .per-pic12 {
          width: 120px;
          height: 120px;
        }
        .per-img20 {
          width: 120px;
          height: 120px;
        }
        .per-pic13 {
          width: 120px;
          height: 120px;
        }
        .per-img21 {
          width: 120px;
          height: 120px;
        }
      }
      .per-mod14 {
        width: 1094px;
        height: 28px;
        margin: 20px 0 0 413px;
        .per-word18 {
          width: 103px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
        }
        .per-word19 {
          width: 69px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
          margin-left: 162px;
        }
        .per-word20 {
          width: 103px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
          margin-left: 161px;
        }
        .per-word21 {
          width: 103px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
          margin-left: 145px;
        }
        .per-word22 {
          width: 103px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
          margin-left: 145px;
        }
      }
      .per-mod15 {
        width: 1179px;
        height: 44px;
        margin: 3px 0 0 370px;
        .per-paragraph3 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .per-infoBox1 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          margin-left: 60px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .per-infoBox2 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          margin-left: 59px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .per-paragraph4 {
          width: 165px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          margin-left: 72px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .per-paragraph5 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          margin-left: 71px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .per-mod16 {
        width: 1111px;
        height: 120px;
        margin: 56px 0 0 404px;
        .per-img22 {
          width: 120px;
          height: 120px;
        }
        .per-pic14 {
          width: 120px;
          height: 120px;
        }
        .per-img23 {
          width: 120px;
          height: 120px;
        }
        .per-img24 {
          width: 120px;
          height: 120px;
        }
        .per-img25 {
          width: 120px;
          height: 120px;
        }
      }
      .per-mod17 {
        width: 1068px;
        height: 28px;
        margin: 20px 0 0 422px;
        .per-info13 {
          width: 86px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
        }
        .per-info14 {
          width: 120px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
          margin-left: 145px;
        }
        .per-word23 {
          width: 69px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
          margin-left: 152px;
        }
        .per-word24 {
          width: 69px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
          margin-left: 179px;
        }
        .per-word25 {
          width: 69px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
          margin-left: 179px;
        }
      }
      .per-mod18 {
        width: 1180px;
        height: 44px;
        margin: 3px 0 0 370px;
        .per-paragraph6 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .per-infoBox3 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .per-word26 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .per-paragraph7 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .per-paragraph8 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .per-mod19 {
        width: 1111px;
        height: 120px;
        margin: 56px 0 0 404px;
        .per-img26 {
          width: 120px;
          height: 120px;
        }
        .per-pic15 {
          width: 120px;
          height: 120px;
        }
        .per-img27 {
          width: 120px;
          height: 120px;
        }
        .per-img28 {
          width: 120px;
          height: 120px;
        }
        .per-pic16 {
          width: 120px;
          height: 120px;
        }
      }
      .per-mod20 {
        width: 1060px;
        height: 28px;
        margin: 20px 0 0 430px;
        .per-txt10 {
          width: 69px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
        }
        .per-word27 {
          width: 69px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
        }
        .per-word28 {
          width: 69px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
        }
        .per-info15 {
          width: 69px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
        }
        .per-word29 {
          width: 69px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
        }
      }
      .per-mod21 {
        width: 1180px;
        height: 44px;
        margin: 3px 0 86px 370px;
        .per-paragraph9 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .per-infoBox4 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .per-txt11 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .per-paragraph10 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .per-infoBox5 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .per-txt12 {
      width: 134px;
      height: 44px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(40, 40, 40, 1);
      font-size: 32px;
      letter-spacing: 2px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 44px;
      text-align: left;
      margin: 120px 0 0 893px;
    }
    .per-info16 {
      width: 898px;
      height: 32px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(102, 102, 102, 1);
      font-size: 18px;
      letter-spacing: 1.5px;
      font-family: AlibabaPuHuiTiR;
      white-space: nowrap;
      line-height: 32px;
      text-align: center;
      margin: 24px 0 0 511px;
    }
    .per-mod22 {
      // z-index: 323;
      height: 822px;
      background-color: rgba(255, 255, 255, 1);
      margin-top: 36px;
      width: 1920px;
      position: relative;
      .per-box7 {
        width: 1215px;
        height: 652px;
        margin: 80px 0 0 352px;
        background: url(../../assets/images/procedure/erpflow.png) 
          100% no-repeat;
        .per-group7 {
          z-index: 402;
          height: 492px;
          background-color: rgba(255, 194, 0, 0.06);
          width: 1214px;
          position: relative;
          justify-content: flex-center;
          .per-wrap1 {
            width: 1142px;
            height: 60px;
            margin: 32px 0 0 36px;
            .per-main9 {
              width: 134px;
              height: 60px;
              border: 1px solid rgba(37, 184, 47, 1);
            }
            .per-main10 {
              height: 60px;
              border: 1px dashed rgba(37, 184, 47, 1);
              background-color: rgba(225, 255, 227, 1);
              width: 157px;
              .per-word30 {
                width: 69px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 44px;
              }
            }
            .per-main11 {
              height: 60px;
              border: 1px dashed rgba(37, 184, 47, 1);
              background-color: rgba(225, 255, 227, 1);
              width: 157px;
              .per-word31 {
                width: 69px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 44px;
              }
            }
            .per-main12 {
              height: 60px;
              border: 1px dashed rgba(37, 184, 47, 1);
              background-color: rgba(225, 255, 227, 1);
              width: 157px;
              .per-word32 {
                width: 69px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 44px;
              }
            }
            .per-main13 {
              height: 60px;
              border: 1px dashed rgba(37, 184, 47, 1);
              background-color: rgba(225, 255, 227, 1);
              width: 157px;
              .per-info17 {
                width: 69px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 44px;
              }
            }
            .per-main14 {
              height: 60px;
              background: url(../../assets/images/back/greenital.png)
                100% no-repeat;
              width: 157px;
              .per-txt13 {
                width: 86px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 35px;
              }
            }
          }
          .per-wrap2 {
            width: 1142px;
            height: 60px;
            margin: 36px 0 0 36px;
            .per-main15 {
              width: 134px;
              height: 60px;
              border: 1px solid rgba(37, 184, 47, 1);
            }
            .per-main16 {
              height: 60px;
              background: url(../../assets/images/back/greensol.png)
                100% no-repeat;
              width: 157px;
              .per-txt14 {
                width: 69px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 44px;
              }
            }
            .per-main17 {
              height: 60px;
              background: url(../../assets/images/back/greensol.png)
                100% no-repeat;
              width: 157px;
              .per-word33 {
                width: 69px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 44px;
              }
            }
            .per-main18 {
              height: 60px;
              background: url(../../assets/images/back/greensol.png)
                100% no-repeat;
              width: 157px;
              .per-txt15 {
                width: 69px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 44px;
              }
            }
            .per-main19 {
              height: 60px;
              background: url(../../assets/images/back/greensol.png)
                100% no-repeat;
              width: 157px;
              .per-word34 {
                width: 69px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 44px;
              }
            }
            .per-main20 {
              height: 60px;
              background: url(../../assets/images/back/greensol.png)
                100% no-repeat;
              width: 157px;
              .per-word35 {
                width: 69px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 44px;
              }
            }
          }
          .per-wrap3 {
            width: 961px;
            height: 60px;
            margin: 20px 0 0 217px;
            .per-bd7 {
              height: 60px;
              background: url(../../assets/images/back/greensol.png)
                100% no-repeat;
              width: 157px;
              .per-word36 {
                width: 69px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 44px;
              }
            }
            .per-bd8 {
              height: 60px;
              background: url(../../assets/images/back/greensol.png)
                100% no-repeat;
              width: 157px;
              .per-info18 {
                width: 86px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 36px;
              }
            }
            .per-bd9 {
              height: 60px;
              background: url(../../assets/images/back/greensol.png)
                100% no-repeat;
              width: 157px;
              .per-word37 {
                width: 69px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 44px;
              }
            }
            .per-bd10 {
              height: 60px;
              background: url(../../assets/images/back/greensol.png)
                100% no-repeat;
              width: 157px;
              .per-txt16 {
                width: 69px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 44px;
              }
            }
            .per-bd11 {
              height: 60px;
              background: url(../../assets/images/back/greensol.png)
                100% no-repeat;
              width: 157px;
              .per-word38 {
                width: 34px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 62px;
              }
            }
          }
          .per-wrap4 {
            width: 1142px;
            height: 60px;
            margin: 36px 0 0 36px;
            .per-block1 {
              width: 134px;
              height: 60px;
              border: 1px solid rgba(37, 184, 47, 1);
            }
            .per-block2 {
              height: 60px;
              border: 1px dashed rgba(37, 184, 47, 1);
              background-color: rgba(225, 255, 227, 1);
              width: 157px;
              .per-info19 {
                width: 69px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 44px;
              }
            }
            .per-block3 {
              height: 60px;
              border: 1px dashed rgba(37, 184, 47, 1);
              background-color: rgba(225, 255, 227, 1);
              width: 157px;
              .per-txt17 {
                width: 86px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 36px;
              }
            }
            .per-block4 {
              height: 60px;
              border: 1px dashed rgba(37, 184, 47, 1);
              background-color: rgba(225, 255, 227, 1);
              width: 157px;
              .per-txt18 {
                width: 103px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 27px;
              }
            }
            .per-block5 {
              height: 60px;
              border: 1px dashed rgba(37, 184, 47, 1);
              background-color: rgba(225, 255, 227, 1);
              width: 157px;
              .per-info20 {
                width: 103px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 27px;
              }
            }
            .per-block6 {
              height: 60px;
              border: 1px dashed rgba(37, 184, 47, 1);
              background-color: rgba(225, 255, 227, 1);
              width: 157px;
              .per-info21 {
                width: 69px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 44px;
              }
            }
          }
          .per-wrap5 {
            width: 1142px;
            height: 60px;
            margin: 36px 0 32px 36px;
            .per-bd12 {
              width: 134px;
              height: 60px;
              border: 1px solid rgba(37, 184, 47, 1);
            }
            .per-bd13 {
              height: 60px;
              background: url(../../assets/images/back/greensol.png)
                100% no-repeat;
              width: 157px;
              .per-txt19 {
                width: 69px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 44px;
              }
            }
            .per-bd14 {
              height: 60px;
              background: url(../../assets/images/back/greensol.png)
                100% no-repeat;
              width: 157px;
              .per-txt20 {
                width: 69px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 44px;
              }
            }
            .per-bd15 {
              height: 60px;
              background: url(../../assets/images/back/greensol.png)
                100% no-repeat;
              width: 157px;
              .per-txt21 {
                width: 69px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 44px;
              }
            }
            .per-bd16 {
              height: 60px;
              background: url(../../assets/images/back/greensol.png)
                100% no-repeat;
              width: 157px;
              .per-word39 {
                width: 69px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 44px;
              }
            }
            .per-bd17 {
              height: 60px;
              background: url(../../assets/images/back/greensol.png)
                100% no-repeat;
              width: 157px;
              .per-word40 {
                width: 69px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 44px;
              }
            }
          }
          .per-word41 {
            z-index: 492;
            position: absolute;
            left: 69px;
            top: 46px;
            width: 69px;
            height: 32px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(18, 112, 24, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            line-height: 32px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .per-word42 {
            z-index: 500;
            position: absolute;
            left: 60px;
            top: 142px;
            width: 86px;
            height: 32px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(18, 112, 24, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            line-height: 32px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .per-word43 {
            z-index: 503;
            position: absolute;
            left: 69px;
            top: 318px;
            width: 69px;
            height: 32px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(18, 112, 24, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            line-height: 32px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .per-word44 {
            z-index: 506;
            position: absolute;
            left: 69px;
            top: 414px;
            width: 69px;
            height: 32px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(18, 112, 24, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            line-height: 32px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .per-group8 {
          z-index: 403;
          height: 124px;
          background-color: rgba(0, 255, 17, 0.05);
          margin-top: 36px;
          width: 1215px;
          position: relative;
          .per-main21 {
            width: 1143px;
            height: 60px;
            margin: 32px 0 0 36px;
            .per-box8 {
              width: 134px;
              height: 60px;
              border: 1px solid rgba(37, 184, 47, 1);
            }
            .per-box9 {
              height: 60px;
              background-color: rgba(37, 184, 47, 1);
              width: 157px;
              .per-word45 {
                width: 26px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(255, 255, 255, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 66px;
              }
            }
            .per-box10 {
              width: 559px;
              height: 60px;
              .per-block7 {
                height: 60px;
                background-color: rgba(37, 184, 47, 1);
                width: 157px;
                .per-info22 {
                  width: 69px;
                  height: 32px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(255, 255, 255, 1);
                  font-size: 16px;
                  letter-spacing: 1.3333333730697632px;
                  font-family: AlibabaPuHuiTiM;
                  line-height: 32px;
                  text-align: center;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  margin: 14px 0 0 44px;
                }
              }
              .per-block8 {
                height: 60px;
                background-color: rgba(37, 184, 47, 1);
                width: 157px;
                .per-info23 {
                  width: 69px;
                  height: 32px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(255, 255, 255, 1);
                  font-size: 16px;
                  letter-spacing: 1.3333333730697632px;
                  font-family: AlibabaPuHuiTiM;
                  line-height: 32px;
                  text-align: center;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  margin: 14px 0 0 44px;
                }
              }
              .per-block9 {
                height: 60px;
                background-color: rgba(37, 184, 47, 1);
                width: 157px;
                .per-txt22 {
                  width: 71px;
                  height: 32px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(255, 255, 255, 1);
                  font-size: 16px;
                  letter-spacing: 1.3333333730697632px;
                  font-family: AlibabaPuHuiTiM;
                  line-height: 32px;
                  text-align: center;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  margin: 14px 0 0 43px;
                }
              }
            }
            .per-box11 {
              height: 60px;
              background-color: rgba(37, 184, 47, 1);
              width: 157px;
              .per-txt23 {
                width: 34px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(255, 255, 255, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 62px;
              }
            }
          }
          .per-info24 {
            z-index: 509;
            position: absolute;
            left: 69px;
            top: 46px;
            width: 69px;
            height: 32px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(18, 112, 24, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            line-height: 32px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .per-txt24 {
        z-index: 496;
        position: absolute;
        left: 830px;
        top: 565px;
        width: 39px;
        height: 32px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(250, 151, 4, 1);
        font-size: 18px;
        letter-spacing: 1.5px;
        font-family: AlibabaPuHuiTiM;
        white-space: nowrap;
        line-height: 32px;
        text-align: center;
      }
      .per-info25 {
        z-index: 497;
        position: absolute;
        left: 1232px;
        top: 565px;
        width: 39px;
        height: 32px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(29, 119, 34, 1);
        font-size: 18px;
        letter-spacing: 1.5px;
        font-family: AlibabaPuHuiTiM;
        white-space: nowrap;
        line-height: 32px;
        text-align: center;
      }
      .per-img13 {
        z-index: 511;
        position: absolute;
        left: 761px;
        top: 548px;
        width: 57px;
        height: 81px;
      }
      .per-img14 {
        z-index: 514;
        position: absolute;
        left: 1283px;
        top: 547px;
        width: 57px;
        height: 81px;
      }
    }
    .per-txt25 {
      width: 134px;
      height: 44px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(40, 40, 40, 1);
      font-size: 32px;
      letter-spacing: 2px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 44px;
      text-align: left;
      margin: 120px 0 0 892px;
    }
    .per-mod23 {
      width: 1261px;
      height: 545px;
      margin: 60px 0 0 330px;
      .per-main22 {
        position: relative;
        width: 516px;
        height: 545px;
        overflow: hidden;
        background: url(../../assets/images/back/erpgreyseven.png)
          100% no-repeat;
        .per-img15 {
          position: absolute;
          left: 0;
          top: 0;
          width: 516px;
          height: 545px;
        }
      }
      .per-main23 {
        width: 645px;
        height: 545px;
        .per-word46 {
          width: 114px;
          height: 29px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(40, 40, 40, 1);
          font-size: 21px;
          letter-spacing: 2px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 29px;
          text-align: left;
        }
        .per-word47 {
          width: 94px;
          height: 20px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(153, 153, 153, 1);
          font-size: 14px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 20px;
          text-align: left;
          margin-top: 6px;
        }
        .per-mod24 {
          width: 84px;
          height: 4px;
          background-color: rgba(37, 184, 47, 1);
          margin-top: 10px;
        }
        .per-word48 {
          width: 54px;
          height: 30px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(40, 40, 40, 1);
          font-size: 16px;
          letter-spacing: 2px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 30px;
          text-align: left;
          margin-top: 20px;
        }
        .per-paragraph11 {
          width: 645px;
          height: 90px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 14px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          line-height: 30px;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .per-info26 {
          width: 89px;
          height: 30px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(40, 40, 40, 1);
          font-size: 16px;
          letter-spacing: 2px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 30px;
          text-align: left;
          margin-top: 8px;
        }
        .per-paragraph12 {
          width: 645px;
          height: 90px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 14px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          line-height: 30px;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .per-mod25 {
          width: 312px;
          height: 77px;
          margin: 32px 0 0 64px;
          .per-word49 {
            width: 54px;
            height: 22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 12px;
            letter-spacing: 1.5px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 22px;
            text-align: left;
            margin-top: 55px;
          }
          .per-word50 {
            width: 86px;
            height: 55px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(37, 184, 47, 1);
            font-size: 40px;
            letter-spacing: 2px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 55px;
            text-align: left;
            margin-left: -54px;
          }
          .per-block10 {
            width: 18px;
            height: 32px;
            background: url(../../assets/images/procedure/hook.png) -1px -2px
              no-repeat;
            margin: 27px 0 0 -2px;
          }
          .per-word51 {
            width: 86px;
            height: 55px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(37, 184, 47, 1);
            font-size: 40px;
            letter-spacing: 2px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 55px;
            text-align: left;
            margin-left: 108px;
          }
          .per-info27 {
            width: 80px;
            height: 22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 12px;
            letter-spacing: 1.5px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 22px;
            text-align: left;
            margin: 55px 0 0 -86px;
          }
          .per-block11 {
            width: 18px;
            height: 32px;
            background: url(../../assets/images/procedure/hook.png) -1px -2px
              no-repeat;
            margin: 27px 0 0 4px;
          }
        }
        .per-mod26 {
          height: 60px;
          background-color: rgba(37, 184, 47, 1);
          box-shadow: 0px 12px 24px 0px rgba(37, 184, 47, 0.2);
          margin-top: 39px;
          width: 180px;
          cursor: pointer;
          .per-txt26 {
            width: 85px;
            height: 29px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 21px;
            letter-spacing: 0.41999998688697815px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 29px;
            text-align: left;
            margin: 15px 0 0 47px;
          }
        }
      }
    }
    .per-txt27 {
      width: 181px;
      height: 50px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(40, 40, 40, 1);
      font-size: 36px;
      letter-spacing: 0.7200000286102295px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 50px;
      text-align: left;
      margin: 120px 0 0 869px;
    }
    .per-mod27 {
      width: 1236px;
      height: 264px;
      margin: 32px 0 0 342px;
      .per-img16 {
        width: 340px;
        height: 264px;
      }
      .per-label1 {
        width: 46px;
        height: 25px;
        margin: 120px 0 0 28px;
      }
      .per-img17 {
        width: 340px;
        height: 264px;
        margin-left: 34px;
      }
      .per-icon1 {
        width: 46px;
        height: 25px;
        margin: 120px 0 0 34px;
      }
      .per-pic9 {
        width: 340px;
        height: 264px;
        margin-left: 28px;
      }
    }
    .per-mod28 {
      width: 969px;
      height: 25px;
      margin-left: 476px;
      .per-word52 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
      .per-info28 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
      .per-info29 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
    }
    .per-mod29 {
      width: 1141px;
      height: 48px;
      margin: 6px 0 0 390px;
      .per-infoBox6 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .per-paragraph13 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .per-infoBox7 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .per-mod30 {
      width: 1236px;
      height: 264px;
      margin: 40px 0 0 342px;
      .per-pic10 {
        width: 340px;
        height: 264px;
      }
      .per-label2 {
        width: 46px;
        height: 25px;
        margin: 120px 0 0 28px;
      }
      .per-img18 {
        width: 340px;
        height: 264px;
        margin-left: 34px;
      }
      .per-label3 {
        width: 46px;
        height: 25px;
        margin: 120px 0 0 34px;
      }
      .per-img19 {
        width: 340px;
        height: 264px;
        margin-left: 28px;
      }
    }
    .per-mod31 {
      width: 969px;
      height: 25px;
      margin-left: 476px;
      .per-info30 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
      .per-info31 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
      .per-txt28 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
    }
    .per-mod32 {
      width: 1141px;
      height: 48px;
      margin: 6px 0 0 390px;
      .per-paragraph14 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .per-paragraph15 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .per-paragraph16 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .per-mod33 {
      height: 527px;
      background-color: rgba(38, 38, 38, 1);
      margin-top: 120px;
      width: 1920px;
      .per-layer11 {
        width: 1520px;
        height: 362px;
        margin: 81px 0 0 201px;
        .per-mod34 {
          width: 1015px;
          height: 36px;
          margin-left: 128px;
          .per-txt29 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .per-txt30 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            margin-left: 156px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .per-info32 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            margin-left: 116px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .per-word53 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            margin-left: 116px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .per-info33 {
            width: 87px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 21px;
            letter-spacing: 1px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 36px;
            text-align: left;
            margin-left: 388px;
          }
        }
        .per-mod35 {
          width: 1138px;
          height: 56px;
          margin: 20px 0 0 128px;
          .per-group9 {
            width: 73px;
            height: 56px;
            .per-word54 {
              width: 73px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .per-word55 {
              width: 64px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .per-group10 {
            width: 67px;
            height: 56px;
            margin-left: 121px;
            .per-word56 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .per-word57 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .per-word58 {
            width: 67px;
            height: 24px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 0.761904776096344px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 24px;
            text-align: left;
            margin-left: 87px;
          }
          .per-word59 {
            width: 67px;
            height: 24px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 0.761904776096344px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 24px;
            text-align: left;
            margin-left: 87px;
          }
          .per-info34 {
            width: 210px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 0.761904776096344px;
            font-family: AlibabaPuHuiTiR;
            line-height: 36px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 16px 0 0 359px;
          }
        }
        .per-mod36 {
          width: 1262px;
          height: 88px;
          margin: 8px 0 0 128px;
          .per-mod37 {
            width: 77px;
            height: 88px;
            .per-word60 {
              width: 68px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .per-info35 {
              width: 77px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
            .per-word61 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .per-mod38 {
            width: 67px;
            height: 56px;
            margin-left: 117px;
            .per-info36 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .per-txt31 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .per-mod39 {
            width: 334px;
            height: 84px;
            margin-left: 667px;
            .per-info37 {
              width: 334px;
              height: 36px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              line-height: 36px;
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .per-word62 {
              width: 220px;
              height: 36px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              line-height: 36px;
              text-align: left;
              margin-top: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .per-txt32 {
          width: 68px;
          height: 24px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 0.761904776096344px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 24px;
          text-align: left;
          margin: 8px 0 0 128px;
        }
        .per-info38 {
          width: 632px;
          height: 24px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 0.8);
          font-size: 14px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 24px;
          text-align: left;
          margin: 81px 0 0 443px;
        }
        .per-mod40 {
          width: 1520px;
          height: 1px;
          margin-top: 16px;
        }
      }
    }
  }
}
body * {
  box-sizing: border-box;
  flex-shrink: 0;
}
body {
  font-family: PingFangSC-Regular, Roboto, Helvetica Neue, Helvetica, Tahoma,
    Arial, PingFang SC-Light, Microsoft YaHei;
}
button {
  margin: 0;
  padding: 0;
  border: 1px solid transparent;
  outline: none;
  background-color: transparent;
}

button:active {
  opacity: 0.6;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.justify-start {
  display: flex;
  justify-content: flex-start;
}
.justify-center {
  display: flex;
  justify-content: center;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}
.justify-evenly {
  display: flex;
  justify-content: space-evenly;
}
.justify-around {
  display: flex;
  justify-content: space-around;
}
.justify-between {
  display: flex;
  justify-content: space-between;
}
.align-start {
  display: flex;
  align-items: flex-start;
}
.align-center {
  display: flex;
  align-items: center;
}
.align-end {
  display: flex;
  align-items: flex-end;
}
</style>

